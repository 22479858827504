<script>
import {
  showPrecatoria,
  newPrecatoria,
  updatePrecatoria
} from "@/domain/processos/services/carta-precatoria";
import {listTiposRecursoAll} from "@/domain/processos/services/tipo-recurso";
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  ErpSelect,
} from 'uloc-vue-plugin-erp'
import WindowLoading from "../../../../layout/window/Loading"
import ERow from "../../../../layout/components/Row";
import ECol from "../../../../layout/components/Col";
import UfSelect from "../../../../remocao/components/include/UfSelect";
import {list as listTribunais} from "../../../../../domain/processos/services/tribunal"
import {list as listComarcas} from "../../../../../domain/processos/services/comarca"
import {list as listVaras} from "../../../../../domain/processos/services/vara"
import {list, list as listAdvogados} from "../../../../../domain/pessoa/services/index"
import {listAllInstancias} from "@/domain/processos/services/instancia";
import {listTags} from "../../../../../domain/pessoa/services/index";
import DateInput from "../../../../../reuse/input/Date";
import {convertDate, datePtToEn} from "@/utils/date";
import {STATUS_CARTA_PRECATORIA_FORMATADO} from "@/domain/processos/helpers/status-cartas-precatorias";
import {isReadyToSubmit, turnCamelCaseStringReadable} from "../utils/utils";
import ProcessoMixins from "../mixins/ProcessoMixins";
import WindowSuccess from "@/components/layout/window/Success";
import TimerCloseWindow from "@/components/layout/window/TimerClose";
import Autocomplete from "@/components/layout/components/Autocomplete"

const mock = {
  recurso: null,
  data: null,
  status: null,
  cidade: null,
  uf: null,
  observacoes: null,
  comarcaId: null,
  vara: null,
  orgao: null,
  tipoRecurso: null,
  posicaoCliente: null,
  instancia: null,
  advogado: null,
  processo: null
}

const searchPerson = function (terms) {
  let filtros = encodeURI(`&sortBy=name&descending=false&startWith=${terms}`)
  return list(50, 1, filtros)
}

const MAX_LIMIT_LOAD_SELECTS = 5000

export default {
  name: "RecursoCartaPrecatoria",
  props: ['id', 'processoId'],
  mixins: [ProcessoMixins],
  components: {
    TimerCloseWindow,
    WindowSuccess,
    DateInput,
    UfSelect,
    ECol,
    ERow,
    ErpInput,
    ErpSelect,
    ErpSField,
    WindowContent,
    WindowFooter,
    WindowLoading,
    Autocomplete
  },
  data () {
    return {
      precatoria: JSON.parse(JSON.stringify(mock)),
      loading: !!this.id,
      errors: null,
      status: null,
      success: false,
      cache: {
        tiposRecurso: {
          list: [],
          loading: false
        },
        tribunais: {
          list: [],
          loading: false
        },
        comarcas: {
          list: [],
          loading: false,
          tmp: null
        },
        varas: {
          list: [],
          loading: false
        },
        instancias: {
          list: [],
          loading: false
        },
        advogados: {
          list: [],
          loading: false
        },
        tags: {
          list: [],
          loading: false
        },
        statuses: {
          list: STATUS_CARTA_PRECATORIA_FORMATADO,
          loading: false
        }
      }
    }
  },
  created () {
    this.loadTiposRecurso()
    this.loadTribunais()
    this.loadInstancias()
    //this.loadAdvogados()
    this.loadTags()
  },
  mounted () {
    this.precatoria.processo = this.processoId
    this.load()
  },
  methods: {
    load () {
      if (this.id){
        this.loading = true
        showPrecatoria(this.id)
            .then(response => {
              console.log(response)
              const data = response.data
              data.data = convertDate(data.data)
              data.processo = data.processo?.id
              data.orgao = data.orgao?.id
              data.instancia = data.instancia?.id
              data.tipoRecurso = data.tipoRecurso?.id
              const tmp = data.vara?.comarca?.id || data.comarcaId
              data.vara = data.vara?.id
              data.posicaoCliente = data.posicaoCliente?.id
              this.loadComarcasByTribunal(data.orgao)
              this.loadVarasByComarca(tmp)
              this.cache.comarcas.tmp = tmp
              if (data.advogado) {
                data.advogado = {
                  ...response.data.advogado,
                  value: response.data.advogado.name,
                  label: response.data.advogado.name
                }
              }
              this.precatoria = data
              this.loading = false
              setTimeout(() => {
                this.precatoria.status = response.data.status
                console.log('alterou')
              }, 5000)
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {
              this.loading = false
            })
      }
    },
    loadTiposRecurso () {
      this.loadCache('tiposRecurso', listTiposRecursoAll)
    },
    loadInstancias () {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, '&sortBy=nome']
      this.loadCache('instancias', listAllInstancias, parameters)
    },
    loadTribunais () {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, '&sortBy=nome']
      this.loadCache('tribunais', listTribunais, parameters)
    },
    loadComarcasByTribunal (id) {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, `&tribunal=${id}&sortBy=nome`]
      const callback = c => ({label: `${c.codigo} - ${c.nome}`, value: c.id})
      this.loadCache('comarcas', listComarcas, parameters, callback)
    },
    loadVarasByComarca (v) {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, `&comarca=${v}&sortBy=nome`]
      this.loadCache('varas', listVaras, parameters)
    },
    loadAdvogados () {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, `&tags=Advogado&sortBy=nome`]
      const callback = c => ({label: c.name, value: c.id})
      this.loadCache('advogados', listAdvogados, parameters, callback)
    },
    loadTags () {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, `&sortBy=name`]
      const callback = c => ({label: c.name, value: c.id})
      this.loadCache('tags', listTags, parameters, callback)
    },
    save () {
      const precatoria = JSON.parse(JSON.stringify(this.precatoria))
      const requiredFields = ['recurso: número', 'data', 'status', 'tipoRecurso: tipo', 'orgao: órgão']
      const callback = field => {
        field = field.split(':')
        field = field[field.length - 1]
        this.$uloc.notify({
          type: 'negative',
          message: `Preencha o campo "${turnCamelCaseStringReadable(field)}"`
        });
      }
      if (!isReadyToSubmit(precatoria, requiredFields, callback)) return
      precatoria.data = datePtToEn(precatoria.data)
      precatoria.recurso = precatoria.recurso.replace(/\D/g, '')
      precatoria.comarcaId = this.cache.comarcas.tmp
      this.loading = true
      this.status = `${this.precatoria.id ? 'Atualizando' : 'Cadastrando'} informações, aguarde...`

      if (precatoria.advogado && precatoria.advogado.id) {
        precatoria.advogado = precatoria.advogado.id
      }

      const method = this.precatoria.id ? updatePrecatoria(this.precatoria.id, precatoria) : newPrecatoria(precatoria)
      method
          .then(response => {
            this.success = true
            precatoria.id = this.precatoria.id = response.data.id
            this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            this.$nextTick(() => {
              this.$refs.timer.start()
            })
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
            })
          })
          .finally (() => {
            this.loading = false
          })
    },
    searchPerson(terms, done) {
      searchPerson(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova pessoa`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    }
  }
}
</script>

<template>
  <window-content v-if="!loading && !errors && !success" class="w-m-content">
    <div class="m-t">
      <e-row class="e-input-modern size1 def-m-r">
        <e-col style="max-width: 100px">
          <erp-s-field label="ID:">
            <erp-input simple-border v-model="precatoria.id" disable />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Número">
            <erp-input simple-border v-model="precatoria.recurso" v-mask="'#######-##.####.#.##.####'" required autofocus />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="e-input-modern size1 def-m-r">
        <e-col>
          <erp-s-field label="Status">
            <erp-select placeholder="Selecione"
                        :options="cache.statuses.list"
                        v-model="precatoria.status"
            />
          </erp-s-field>
        </e-col>
        <e-col>
          <date-input label="Data" v-model="precatoria.data" />
        </e-col>
        <e-col>
          <erp-s-field label="Tipo">
            <erp-select placeholder="Selecione"
                        :options="cache.tiposRecurso.list"
                        v-model="precatoria.tipoRecurso"
                        :loading="cache.tiposRecurso.loading"
                        :disable="cache.tiposRecurso.loading"
            />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="e-input-modern size1 def-m-r">
        <e-col>
          <erp-s-field label="Cidade">
            <erp-input simple-border v-model="precatoria.cidade" />
          </erp-s-field>
        </e-col>
        <e-col  style="max-width: 160px">
          <uf-select v-model="precatoria.uf"></uf-select>
        </e-col>
      </e-row>
      <e-row class="e-input-modern size1 def-m-r">
        <e-col>
          <erp-s-field label="Órgão">
            <erp-select placeholder="Selecione"
                        :options="cache.tribunais.list"
                        v-model="precatoria.orgao"
                        :loading="cache.tribunais.loading"
                        :disable="cache.tribunais.loading"
                        @input="loadComarcasByTribunal"
            />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="e-input-modern size1 def-m-r">
        <e-col>
          <erp-s-field label="Comarca">
            <erp-select placeholder="Selecione"
                        :options="cache.comarcas.list"
                        v-model="cache.comarcas.tmp"
                        :loading="cache.comarcas.loading"
                        :disable="cache.comarcas.loading || !precatoria.orgao"
                        @input="loadVarasByComarca"
            />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Vara">
            <erp-select placeholder="Selecione"
                        :options="cache.varas.list"
                        v-model="precatoria.vara"
                        :disable="cache.varas.loading || !cache.comarcas.tmp"
                        :loading="cache.varas.loading"
            />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="e-input-modern size1 def-m-r">
        <e-col>
          <erp-s-field label="Instância">
            <erp-select placeholder="Selecione"
                        :options="cache.instancias.list"
                        v-model="precatoria.instancia"
                        :disable="cache.instancias.loading"
                        :loading="cache.instancias.loading"
            />
          </erp-s-field>
        </e-col>
      </e-row>
<!--
      <e-row class="e-input-modern size1 def-m-r">
        <e-col>
          <erp-s-field label="Posição do cliente">
            <erp-select placeholder="Selecione"
                        :options="cache.tags.list"
                        v-model="precatoria.posicaoCliente"
                        :disable="cache.tags.loading"
                        :loading="cache.tags.loading"
            />
          </erp-s-field>
        </e-col>
      </e-row>
-->
      <e-row class="e-input-modern size1 def-m-r">
        <e-col>
          <erp-s-field
            :view="'tl'"
            :label="'Advogado'"
            :helper-position="'lb'"
        >
          <autocomplete :search="searchPerson" v-model="precatoria.advogado" icon="user"/>
        </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="e-input-modern size1 def-m-r">
        <e-col>
          <erp-s-field label="Observações">
            <textarea v-model="precatoria.observacoes" class="full-width" rows="10" ></textarea>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="flex content-between">
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn :disable="loading" label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 v-if="!id" class="m-t">Registrado com sucesso!</h5>
    <h5 v-else class="m-t">Atualizado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else></window-loading>
</template>
