import http from "../../../utils/services/http";

export const list = (limit, page, filtros) => {
    let url = `/api/processos/instancias?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listAllInstancias = () => {
    let url = '/api/processos/all-instancias'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}