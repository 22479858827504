import http from "../../../utils/services/http";

export const listTiposRecursoAll = () => {
    let url = '/api/processos/all-tipo-recurso'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}